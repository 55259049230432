<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <h2 class="login-title">비밀번호 재설정</h2>
            <v-layout column align-center class="text-center">
                <v-img max-width="60" src="/images/icon/icon-check-circle-outline.svg" class="mb-18 mb-md-24" />
                <h4 class="tit">비밀번호 재설정이 완료되었습니다.</h4>
                <v-btn to="/" x-large rounded dark color="primary" class="rounded-xs mt-40 mt-md-48">
                    <span class="tit tit--xxs">메인으로 이동</span>
                </v-btn>
            </v-layout>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import FindPasswordInfo from "./find-password-info.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";
import TableRow from "@/components/dumb/table-row.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        FindPasswordInfo,
        TablePrimary,
        TableRow,
    },
};
</script>
