<template>
    <v-form v-model="isValid">
        <client-page>
            <page-section class="page-section--first page-section--last">
                <h2 class="login-title">비밀번호 재설정</h2>
                <div class="font-size-24 font-size-md-28 line-height-1 mb-16 mb-md-20">비밀번호 재설정</div>
                <table-primary>
                    <table-row title="비밀번호">
                        <v-password-field v-model="password" :rules="rules.password" type="password" placeholder="새로운 비밀번호를 입력하세요." outlined />
                    </table-row>
                    <table-row title="비밀번호 확인">
                        <v-password-confirm-field type="password" :password="password" placeholder="비밀번호를 재입력 하세요." outlined />
                    </table-row>
                </table-primary>

                <div class="v-btn--group d-flex justify-center">
                    <v-btn large rounded color="primary" class="rounded-xs" :disabled="disabled" @click="recovery">변경 완료</v-btn>
                </div>
            </page-section>
        </client-page>
    </v-form>
</template>

<script>
import api from "@/api";
import cryptoAes from "@/plugins/crypto-aes";
import { rules } from "@/assets/variables";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import FindPasswordInfo from "./find-password-info.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";
import TableRow from "@/components/dumb/table-row.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        FindPasswordInfo,
        TablePrimary,
        TableRow,
        VPasswordConfirmField,
        VPasswordField,
    },
    data: () => ({
        rules,
        password: "",
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    methods: {
        async recovery() {
            try {
                if (!this.validate) return;
                let _user = this.$route.query._user;
                if (!_user) throw new Error("본인인증이 필요합니다.");

                let password = cryptoAes.encrypt(this.password);
                await api.v1.users.recovery.post({ _user, password });

                this.$router.push("/login/reset-password-complete");
            } catch (error) {
                this.$handleError(error);
            }
        },
        validate() {
            if (!this.password) throw new Error("비밀번호를 입력해주세요");

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-text-field {
        height: auto !important;
        min-height: 36px !important;
        .v-input__slot {
            margin-bottom: 0 !important;
            min-height: initial !important;
        }
        .v-messages__message {
            margin-bottom: 4px;
        }
    }
    @media (min-width: 768px) {
        .v-text-field {
            min-height: 48px !important;
        }
    }
}
</style>
