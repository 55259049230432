<template>
    <a v-bind:id="'kakao-login-btn'" class="button button--social-k" v-bind:href="`https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`">
        <slot default>카카오 계정으로 로그인</slot>
    </a>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" }
    },
    computed: {
        redirect_uri: function(){
            return `${document.location.protocol}//${document.location.hostname}/oauth/kakao`;
        }
    }

}
</script>
