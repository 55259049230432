<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <h2 class="login-title"><span>비밀번호 찾기</span></h2>
            <v-row justify="center" class="row--xl">
                <v-col cols="12" xl="6">
                    <v-card outlined class="rounded-lg pa-16 px-md-84 py-md-60">
                        <v-text-field v-model="username" placeholder="아이디" outlined hide-details class="mb-8 mb-md-12" />
                        <v-btn block x-large color="#FF3A4A" class="white--text" @click="submit">본인 인증 하기</v-btn>
                        <div class="mt-8 mt-md-12">
                            <v-row no-gutters justify="center" align="center" class="ma-n4 ma-md-n6">
                                <v-col cols="auto" class="pa-4 pa-md-6">
                                    <v-btn text small><router-link to="/login" color="grey darken-4">로그인</router-link></v-btn>
                                </v-col>
                                <v-col cols="auto" class="pa-4 pa-md-6">
                                    <v-divider vertical class="grey lighten-4" style="height: 12px" />
                                </v-col>
                                <v-col cols="auto" class="pa-4 pa-md-6">
                                    <v-btn text small><router-link to="/login/find-id" color="grey darken-4">아이디 찾기</router-link></v-btn>
                                </v-col>
                                <v-col cols="auto" class="pa-4 pa-md-6">
                                    <v-divider vertical class="grey lighten-4" style="height: 12px" />
                                </v-col>
                                <v-col cols="auto" class="pa-4 pa-md-6">
                                    <v-btn text small><router-link to="/join" color="grey darken-4">회원가입</router-link></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>
        <kcp-cert ref="kcpCert" @input="verify" />
    </client-page>
</template>

<script>
import api from "@/api";
import cryptoAes from '@/plugins/crypto-aes';
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import FindPasswordInfo from "./find-password-info.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        FindPasswordInfo,
        KcpCert
    },
    data() {
        return {
            username: null,
        };
    },
    methods: {
        validate() {
            if (!this.username) throw new Error("아이디를 입력해주세요");

            return true;
        },

        submit() {
            try {
                if (!this.validate()) return false;
                this.$refs.kcpCert.auth();
            } catch (error) {
                alert(error.message.toString());
            }
        },

        async verify(payload) {
            try {
                let { ci } = payload;
                ci = cryptoAes.encrypt(ci.replace(/[ ]/g, "+"));
                const { _user } = await api.v1.users.existence.post({ _user: this.$route.query._user, ci, username: this.username });

                if(!_user) throw new Error("회원이 존재하지 않습니다.");

                this.$router.push({ path: "/login/reset-password", query: { _user }});
            } catch(error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
