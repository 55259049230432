var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "button button--social-k",
    attrs: {
      "id": 'kakao-login-btn',
      "href": `https://kauth.kakao.com/oauth/authorize?client_id=${_vm.client_id}&redirect_uri=${_vm.redirect_uri}&response_type=code`
    }
  }, [_vm._t("default", function () {
    return [_vm._v("카카오 계정으로 로그인")];
  }, {
    "default": ""
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }