var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "centered": "",
      "multi-line": "",
      "outlined": "",
      "timeout": -1
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card-title', [_vm._v(" 사이트 리뉴얼 안내 ")]), _c('v-card-text', [_vm._v(" ※ 이전 용산시시제품 제작소 이용자는 아이디와 이메일 모두 이메일을 입력해주세요 ")]), _c('v-card-actions', [_c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v(" 닫기 ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }