<template>
    <v-snackbar v-model="shows" centered multi-line outlined :timeout="-1">
        <v-card-title> 사이트 리뉴얼 안내 </v-card-title>
        <v-card-text> ※ 이전 용산시시제품 제작소 이용자는 반드시 비밀번호 변경을 진행하신 후 로그인 해주세요 </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col>
                    <v-btn x-large block color="secondary" to="/login/find-password"> 비밀번호변경하러가기 </v-btn>
                </v-col>
                <v-col>
                    <v-btn x-large block outlined @click="shows = false"> 닫기 </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-snackbar>
</template>

<script>
export default {
    data: () => ({
        shows: true,
    }),
};
</script>

<style>
</style>