var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v("비밀번호 찾기")])]), _c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg pa-16 px-md-84 py-md-60",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mb-8 mb-md-12",
    attrs: {
      "placeholder": "아이디",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "block": "",
      "x-large": "",
      "color": "#FF3A4A"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("본인 인증 하기")]), _c('div', {
    staticClass: "mt-8 mt-md-12"
  }, [_c('v-row', {
    staticClass: "ma-n4 ma-md-n6",
    attrs: {
      "no-gutters": "",
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/login",
      "color": "grey darken-4"
    }
  }, [_vm._v("로그인")])], 1)], 1), _c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "grey lighten-4",
    staticStyle: {
      "height": "12px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/login/find-id",
      "color": "grey darken-4"
    }
  }, [_vm._v("아이디 찾기")])], 1)], 1), _c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "grey lighten-4",
    staticStyle: {
      "height": "12px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/join",
      "color": "grey darken-4"
    }
  }, [_vm._v("회원가입")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }