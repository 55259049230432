var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm.caption ? _c('p', {
    staticClass: "text-right page-text page-text--sm grey--text line-height-1 mb-12 mb-md-20"
  }, [_c('span', {
    staticStyle: {
      "color": "#E91E63"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다. ")]) : _vm._e(), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-top-width": "2px"
    }
  }), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }