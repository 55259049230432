<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <h2 class="login-title"><span>아이디 찾기</span></h2>
            <v-row justify="center" class="row--xl">
                <v-col cols="12" md="6">
                    <v-card outlined class="rounded-lg pa-20 pa-md-60 text-center">
                        <div class="font-size-24 font-size-md-28 mb-18 mb-md-24">
                            <strong>{{ user?.name }}</strong
                            >님의 아이디는 다음과 같습니다.
                        </div>
                        <v-sheet min-width="200" color="grey lighten-5" rounded="pill" class="d-inline-flex mx-auto justify-center align-center pa-24">
                            <div class="tit tit--xxs line-height-1">{{ user?.username }}</div>
                        </v-sheet>
                    </v-card>
                    <div class="v-btn--group">
                        <v-row justify="center" class="row--xs">
                            <v-col cols="auto">
                                <v-btn :to="`/login/find-password?_user=${user?._id}`" large rounded outlined color="grey lighten-3" class="rounded-xs">
                                    <span class="grey--text">비밀번호 찾기</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn to="/login" large rounded dark color="primary" class="rounded-xs">
                                    <span>로그인</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data: () => ({
        user: {},
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let _user = this.$route.query._user;
                if (!_user) throw new Error("본인인증이 필요합니다.");
                let { _id, username, name } = await api.v1.users.get({ _id: _user });

                this.user = { _id, username, name };
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
