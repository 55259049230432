var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-row"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-n18 mb-md-0 min-w-md-180px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-12 py-md-16 px-md-20"
  }, [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _vm.pointer ? _c('span', {
    staticStyle: {
      "color": "#e91e63"
    }
  }, [_vm._v("*")]) : _vm._e()])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "page-text page-text--sm grey--text pa-12 py-md-16 px-md-20"
  }, [_vm._t("default")], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }