var render = function render(){
  var _vm$user, _vm$user2, _vm$user3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v("아이디 찾기")])]), _c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg pa-20 pa-md-60 text-center",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 mb-18 mb-md-24"
  }, [_c('strong', [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name))]), _vm._v("님의 아이디는 다음과 같습니다. ")]), _c('v-sheet', {
    staticClass: "d-inline-flex mx-auto justify-center align-center pa-24",
    attrs: {
      "min-width": "200",
      "color": "grey lighten-5",
      "rounded": "pill"
    }
  }, [_c('div', {
    staticClass: "tit tit--xxs line-height-1"
  }, [_vm._v(_vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.username))])])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "to": `/login/find-password?_user=${(_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3._id}`,
      "large": "",
      "rounded": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("비밀번호 찾기")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "to": "/login",
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "primary"
    }
  }, [_c('span', [_vm._v("로그인")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }