<template>
    <v-sheet>
        <p v-if="caption" class="text-right page-text page-text--sm grey--text line-height-1 mb-12 mb-md-20">
            <span style="color: #E91E63;">*</span> 필수입력 항목입니다.
        </p>
        <v-divider class="grey darken-4" style="border-top-width: 2px" />
        <slot />
    </v-sheet>
</template>

<script>
export default {    
    props : {
        caption: { type: Boolean, default: false },
    },
}
</script>

<style>

</style>