var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_vm._v("비밀번호 재설정")]), _c('v-layout', {
    staticClass: "text-center",
    attrs: {
      "column": "",
      "align-center": ""
    }
  }, [_c('v-img', {
    staticClass: "mb-18 mb-md-24",
    attrs: {
      "max-width": "60",
      "src": "/images/icon/icon-check-circle-outline.svg"
    }
  }), _c('h4', {
    staticClass: "tit"
  }, [_vm._v("비밀번호 재설정이 완료되었습니다.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-40 mt-md-48",
    attrs: {
      "to": "/",
      "x-large": "",
      "rounded": "",
      "dark": "",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("메인으로 이동")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }